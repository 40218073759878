@import "https://use.fontawesome.com/ede1f9233a.css";
@import "variables";

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  font-weight: bold;
}

main {
  a {
    text-decoration: underline;
  }
  P {
    margin-bottom: 0.75rem;
  }
}

div[class^="block"] {
  a {
    &:hover {
      font-weight: bolder;
      color: #22586f;
    }
  }
}

div[class*="block navigation"] {
  ul {
    margin: 0;
  }
}

div[class*="meetingsSmall"] {
  time {
    display: none;
  }
}

// hide social channels
div[class*="CrmSiteProfileMutationsEditPerson_hyves"], [class*="CrmSiteProfileMutationsEditPerson_facebook"], [class*="CrmSiteProfileMutationsEditPerson_twitter"] {
  display: none !important;
}

.intro p, .intro {
  font-weight: bold;
}

.header.variant2 {
  .topBar a {
    color: #fff;
  }
  .bottom .toggle-topbar.menu-icon + .left img {
    max-height: 100px;
    margin-bottom: 25px;
  }
  .top-bar-section ul {
    padding-top: 25px;
  }
  @media #{$medium-down} {
    .bottom .toggle-topbar.menu-icon + .left img {
      max-height: 60px;
      height: auto;
      margin-bottom: 0;
    }
  }
}

.buttons {
  font-weight: bold;
  margin-top: 25px;
  a.btn-red {
    background: rgba(221, 19, 107, 1);
    padding: 6px 12px;
    border-radius: 5px;
  }
  a.btn-blue {
    background: rgba(39, 102, 129, 1);
    padding: 6px 12px;
    border-radius: 5px;
  }
  a.btn-regular {
    background: rgba(204, 204, 204, 1);
    padding: 6px 12px;
    border-radius: 5px;
  }
  a.btn-regular:hover {
    background: rgba(204, 204, 204, 0.7);
  }
  a.btn-red:hover {
    background: rgba(221, 19, 107, 0.7);
  }
  a.btn-blue:hover {
    background: rgba(39, 102, 129, 0.7);
  }
}

span.logoutBtn {
  float: right;
  display: inline-block;
  clear: both;
  margin-top: 25px;
  margin-left: 25px;
}

/*.caption {
  border-radius: 15px;
  h3 {
    font-weight: bold;
  }
}*/
.slider.variant2 .captionInner {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  h3 {
    font-weight: normal;
  }
}

ul.zoekresultatenOverzicht.overzicht {
  list-style-type: none;
  margin-left: 0;
}

span.logoutBtn {
  display: none !important;
}

body.home {
  div[id*="Before_Content"] {
    background-color: #276681;
    .row {
      background-color: #276681;
      section {
        .intro p {
          color: #fff;
        }
        h1 {
          color: #fff;
        }
      }
    }
  }
  .block.navigation {
    background-color: #ededed;
    padding: 20px;
    h3 {
      padding-left: 15px;
    }
  }
}

ul.right {
  span {
    color: #276681;
    cursor: pointer;
  }
  li a:hover {
    border-bottom: rgba(0, 0, 0, 0.1);
  }
}

// specific styling homepage blocks
.highlightRow {
  border-bottom: 0;
  article {
    padding: .9375rem;
  }
  section {
    background-color: #FFF;
    border-radius: .75em;
    margin-bottom: .5em;
    padding: .9375rem;
    time {
      color: #666 !important;
      font-size: 14px;
      font-weight: bold;
    }
    h3 {
      padding: 0 1.3rem;
      font-weight: bold;
    }
    .btn a {
      background-color: #e5e5e5;
      border: none;
      color: #dc006b;
      width: 100%;
      text-align: center;
      margin-top: 0.75rem;
      &:hover {
        font-weight: bold;
      }
    }
  }
}

div[class*="footer"] {
  p {
    margin: $margin-small;
  }
}

div[class*="blockSettings"] {
  &.Blue_bg {
    padding: 20px;
    background-color: #276681;
    color: #fff;
    h1, h2, h3, h4, h5, h6, strong {
      color: #fff;
    }
  }
  &.Pink_bg {
    padding: 20px;
    background-color: #dc006b;
    color: #fff;
    h1, h2, h3, h4, h5, h6, strong {
      color: #fff;
    }
  }
  &.Lightgrey_bg {
    padding: 20px;
    background-color: #ddd;
  }
}

// Custom block picture
.block.picture {
  background-color: #eceded;
  border-radius: .75em;
  margin-bottom: .5em;
  .content {
    padding: .9375rem;
    span a {
      width: 100%;
      text-align: center;
      color: #dc006b;
      &:hover {
        font-weight: bold;
      }
    }
    h3 {
      color: #dc006b;
      font-weight: bolder;
    }
  }
  figure {
    margin: 0;
    img {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
    }
  }
}

section.bg-1 {
  padding: 1.5rem 0;
  background-color: #e5e5e5;
  figure {
    margin: 10px 0;
    img {
      width: 100%;
    }
    figcaption a {
      position: absolute;
      bottom: 20px;
      left: 40px;
      font-size: 1.2rem;
      font-weight: bold;
      width: 50%;
      color: #000;
    }
  }
}

section.bg-2 {
  padding: 1.5rem 0;
  background-color: #e9eff1;
  figure {
    img {
      width: 100%;
    }
    margin: 10px 0;
    figcaption {
      background-color: #326881;
      padding: 20px;
      text-align: center;
      a {
        color: #fff;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }
}

aside {
  section {
    padding: 1.5rem 0;
  }
  &.homepage {
    h3 {
      font-size: 1.5rem;
      color: #fff;
      background-color: #326881;
      padding: 10px;
      font-weight: bold;
    }
    h5 a {
      font-weight: bold;
    }
    strong.readmore {
      color: #d3146a;
    }
    .btn a {
      float: right;
      padding: 5px 20px;
      background-color: #e3e4e3;
      color: #d3146a;
      border: none;
    }
    .widgetBlock {
      padding: 20px;
    }
    .pages.variant2 {
      .custom img {
        display: block;
        margin-bottom: 20px;
      }
    }
    .overzicht.kort.agenda {
      h5 {
        margin-bottom: 0;
      }
      time.meeting {
        color: #326881;
      }
    }
    .social.icons {
      text-align: center;
      a {
        font-size: 1.2rem;
      }
      span.fa.fa {
        font-size: 1.2rem;
        padding-left: 5px;
        background-color: #326881;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        color: #fff;
        &:hover {
          opacity: 0.8;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.changeAttributes.variant1 .modifypersonalattributeinfo_input_container_text {
  float: left;
  label {
    float: left;
  }
  input {
    display: inline-block;
    margin-left: 10px;
  }
}

div[class*="ProfileMutationsEditFunctionRenderControl_functieclassificatie"], div[class*="ProfileMutationsEditFunctionRenderControl_afdeling"], div[class*="ProfileMutationsEditFunctionRenderControl_fax"], div[class*="ProfileMutationsEditFunctionRenderControl_ingangsdatum"] {
  display: none;
}

.SN_Medewerkers_Label {
  display: none;
}

.changepassword_successmessage {
  color: #fff;
  background-color: #dc006b;
  padding: 20px;
}

// hide specific member search data
.memberSearch {
  .contact {
    display: none;
  }
  div[class*="GoogleMapResult_Map"] {
    .adres, .email, .telefoon, .postcode {
      display: none;
    }
  }
}

// TIJDELIJK:
#filter #rowAttributes {
  display: none;
}

// Collega aanmelden verbergen
[ng-if^='nav.rights.colleagues'] {
  display: none;
}

// Membersearch form
#NVRG_Search_Form_NVRGMemberSearchForm_panel_editSubmitButton_Container {
  div[id*="Label_ZoekSysteemtherapeutischWerkerLabel"], div[id*="Label_ZoekSysteemtherapeutLabel"] {
    width: calc(100% - 25px);
    float: right;
    clear: both;
  }
  div[id*="Input_BedrijfPlaats"], div[id*="Input_ZipCode"] {
    //float:left;
    //width:50%;
  }
}

// Membersearch form
.block.ledenzoek.form {
  .checkbox {
    width: 25px;
    display: inline-block;
  }
  #Block_ledenzoekform_NVRGMemberSearchForm_Label_ZoekSysteemtherapeutLabel_Container, #Block_ledenzoekform_NVRGMemberSearchForm_Label_ZoekSysteemtherapeutischWerkerLabel_Container {
    float: right;
    width: calc(100% - 25px);
  }
  input[type="submit"] {
    background: #dd136b;
    padding: 6px 12px;
    color: #fff;
    border: none;
    border-radius: 5px;
    &:hover {
      opacity: 0.7;
    }
  }
}

// Membersearch results
#NVRGMemberSearch {
  padding: 25px 0;
  .pagination {
    margin: 30px 0 2px;
    a, span {
      text-decoration: none;
      width: 20px;
      padding: 10px;
      position: relative;
      height: 20px;
      background-color: #ededed;
      &.previous {
        margin-right: 5px;
      }
    }
  }
  a, p {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .row {
    &.Head {
      margin-bottom: 10px;
      color: #276681;
      //> div {
      //  &:after {
      //    content: "\f107";
      //    font-family: FontAwesome;
      //    font-weight: bold;
      //    margin-left: 5px;
      //    color: #276681;
      //    cursor: pointer;
      //  }
      //}
    }
    &.Results {
      margin-bottom: 7px;
      background-color: #f2f2f2;
      > div {
        padding: 10px 10px;
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
        a {
          text-decoration: none;
        }
        p {
          margin-bottom: 2px;
        }
        i.fa {
          padding-right: 5px;
        }
      }
    }
    &.PageResults {
      padding: 15px 0;
      .center {
        > span {
          width: 35px;
          height: 35px;
          line-height: 2.3;
          display: inline-block;
          background-color: #276681;
          &:hover {
            transition: 0.25s;
            background-color: #dd136b;
          }
          a {
            color: #fff;
            text-decoration: none;
            padding: 10px 13px;
          }
          &.activePage {
            background-color: #dd136b;
          }
        }
      }
      .left span a, .right span a {
        font-size: 14px;
        color: #276681;
        text-decoration: none;
        line-height: 2.3;
        &:hover {
          color: #dd136b;
        }
      }
      .left span a {
        &:before {
          content: "\f053";
          font-family: FontAwesome;
          margin-right: 5px;
          color: #276681;
          cursor: pointer;
          font-size: 12px;
        }
      }
      .right span a {
        float: right;
        &:after {
          content: "\f054";
          font-family: FontAwesome;
          margin-left: 5px;
          color: #276681;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
}

// Membersearch detail
.block.ledenzoek.detail {
  .person {
    ul {
      margin-left:0;
      margin-bottom: 10px;
      li {
        list-style-type: none;
      }
    }
  }
  .instelling {
    i {
      padding-right: 10px;
      width: 30px;
    }
  }
  i.fa.fa-check {
    padding-left: 5px;
  }
  .person {
    background-color: #ededed;
    padding: 25px;
    border-right: 25px solid #fff;
  }
  .instelling {
    background-color: #ededed;
    padding: 25px;
    margin-bottom:15px;
  }
  @media #{$medium-down} {
    .person {
      border-right:0px;
    }
  }
}

.edit_function_companyifo_container {
  div[class*="_vrijveld1"] {
    display: none;
  }
}

#SN_Prive, #SN_Medewerkers {
  table tr > td {
    width: 200px;
  }
}

table.attributes {
  max-width: 500px;
  tr > td {
    width: 200px;
  }
}

.edit_function_container, .add_function_container {
  .label_container.passwordvalidationmessage {
    width: 100%;
    float: left;
  }
}

.CrmSiteProfileMutationsAddFunctionRenderControl_opmerkingen.element_container, .CrmSiteProfileMutationsAddFunctionRenderControl_bedrijfsnaam_kort, .CrmSiteProfileMutationsAddFunctionRenderControl_functieclassificatie, .CrmSiteProfileMutationsAddFunctionRenderControl_afdeling, .CrmSiteProfileMutationsAddFunctionRenderControl_fax, .CrmSiteProfileMutationsAddFunctionRenderControl_ingangsdatum, .CrmSiteProfileMutationsAddFunctionRenderControl_instellenvoorprofiel {
  display: none;
}
